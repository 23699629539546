import PNGAiImage from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai.png";
import PNGAiImage2x from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@2x.png";
import PNGAiImageTab from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@tab.png";
import PNGAiImageTab2x from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@tab2x.png";
import PNGAiImageMob from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@mob.png";
import PNGAiImageMob2x from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@mob2x.png";

import WEBPAiImage from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai.webp";
import WEBPAiImage2x from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@2x.webp";
import WEBPAiImageTab from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@tab.webp";
import WEBPAiImageTab2x from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@tab2x.webp";
import WEBPAiImageMob from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@mob.webp";
import WEBPAiImageMob2x from "../../../assets/images/raster/pages/personal-product-ideas/AmzAi/ai@mob2x.webp";

export const AMZ_AI_CONTENT = {
  LIST: [
    "The highest revenue from $3,000/month",
    "The fastest growth", "The least number of reviews",
    "Stable sales velocity from 100/month"
  ],
  IMAGES: {
    desktop: {
      png: PNGAiImage,
      png2x: PNGAiImage2x,
      webp: WEBPAiImage,
      webp2x: WEBPAiImage2x,
    },
    tab: {
      png: PNGAiImageTab,
      png2x: PNGAiImageTab2x,
      webp: WEBPAiImageTab,
      webp2x: WEBPAiImageTab2x,
    },
    mobile: {
      png: PNGAiImageMob,
      png2x: PNGAiImageMob2x,
      webp: WEBPAiImageMob,
      webp2x: WEBPAiImageMob2x,
    },
  },
};
