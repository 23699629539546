import React from "react";
import Container from "../../common/Container";
import "./index.scss";
import PointList from "../../common/UI/List/PointList";
import { AMZ_AI_CONTENT } from "./index.content";

const { LIST, IMAGES } = AMZ_AI_CONTENT;
const { desktop, tab, mobile } = IMAGES;

const AmzAi = () => {
  return (
    <section className="PgPPI-AmzAi">
      <Container modeClass="PgPPI-AmzAi-container">
        <p className="PgPPI-AmzAi__title">
          AMZScout AI, created by our team of talented developers, compares
          products across multiple criteria in order to make a good choice. Our
          main criteria include:
        </p>
        <div className="PgPPI-AmzAi-box">
          <PointList arrayOfItems={LIST} customClassName="PgPPI-AmzAi__list" />
          <div className="PgPPI-AmzAi-image PgPPI-AmzAi__image">
            <picture>
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${mobile.webp} 1x, ${mobile.webp2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${mobile.png} 1x, ${mobile.png2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px) and (max-width: 1365px)"
                srcSet={`${tab.webp} 1x, ${tab.webp2x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 1365px)"
                srcSet={`${tab.png} 1x, ${tab.png2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 1366px)"
                srcSet={`${desktop.webp} 1x, ${desktop.webp2x} 2x`}
              />
              <source
                media="(min-width: 1366px)"
                srcSet={`${desktop.png} 1x, ${desktop.png2x} 2x`}
              />
              <img src={desktop.png} alt="" loading="lazy" />
            </picture>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AmzAi;
