import React from "react";
import Container from "../../common/Container";
import TrackedLink from "../../Analytics/TrackedLink";
import { REPORT_CTA_CONTENT } from "./index.content";
import "./index.scss";

const { IMAGES } = REPORT_CTA_CONTENT;

const ReportCta = () => {
  return (
    <section className="PgPPI-ReportCta">
      <Container mediumSize modeClass="PgPPI-ReportCta-container">
        <div className="PgPPI-ReportCta-wrapper">
          <p className="PgPPI-ReportCta__title">
            You can be sure that each product idea is unique.
            <br /> Each subscriber will receive their own bestseller in the
            selected subcategory.
          </p>
          <div className="PgPPI-ReportCta-images PgPPI-ReportCta__images">
            {IMAGES.map((item, index) => {
              const { desktop, tab, mobile } = item;
              return (
                <div className="PgPPI-ReportCta-images__item" key={index}>
                  <picture>
                    <source
                      type="image/webp"
                      media="(max-width: 767px)"
                      srcSet={`${mobile.webp} 1x, ${mobile.webp2x} 2x`}
                    />
                    <source
                      media="(max-width: 767px)"
                      srcSet={`${mobile.png} 1x, ${mobile.png2x} 2x`}
                    />
                    <source
                      type="image/webp"
                      media="(min-width: 768px) and (max-width: 991px)"
                      srcSet={`${tab.webp} 1x, ${tab.webp2x} 2x`}
                    />
                    <source
                      media="(min-width: 768px) and (max-width: 991px)"
                      srcSet={`${tab.png} 1x, ${tab.png2x} 2x`}
                    />
                    <source
                      type="image/webp"
                      media="(min-width: 992px)"
                      srcSet={`${desktop.webp} 1x, ${desktop.webp2x} 2x`}
                    />
                    <source
                      media="(min-width: 992px)"
                      srcSet={`${desktop.png} 1x, ${desktop.png2x} 2x`}
                    />
                    <img src={desktop.png} alt="icon" loading="lazy" />
                  </picture>
                </div>
              );
            })}
          </div>
          <div className="UI-Global__center">
            <TrackedLink
              action="CHECK OUT THE SAMPLE REPORT"
              category="PersonalProductIdeasLP"
              path="/goto/?url=https://assets.ctfassets.net/0yu6ph4occja/3ZdidnAfgATCqGf4jbMWcI/9f705e4989ccc6856d05cbb9b9669526/PRODUCT-REPORT.pdf"
              class="PgPPI-ReportCta__cta"
              target
            >
              CHECK OUT THE SAMPLE REPORT
            </TrackedLink>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ReportCta;
