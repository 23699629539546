import React from "react";
import "./index.scss";
import Container from "../../common/Container";
import TrackedLink from "../../Analytics/TrackedLink";
import { INSTRUCTION_CONTENT } from "./index.content";

const Instruction = () => {
  return (
    <section className="PgPPI-Instruction">
      <Container modeClass="PgPPI-Instruction-container">
        <p className="PgPPI-Instruction__title">How Does It Work?</p>
        <ul className="PgPPI-Instruction-list">
          {INSTRUCTION_CONTENT.LIST.map((item, index) => {
            return (
              <li key={index} className="PgPPI-Instruction-list__item">
                <p>{item.number}</p>
                <p>{item.description}</p>
                {item.path && (
                  <TrackedLink
                    category="PersonalProductIdeasLP"
                    action="Click here"
                    path={item.path}
                    class="PgPPI-Instruction-list__button"
                  >
                    Click here
                  </TrackedLink>
                )}
              </li>
            );
          })}
        </ul>
        <p className="PgPPI-Instruction__description">
          We are ready to meet your requirements in any subcategory, from
          Christmas shirts and 3d printing to the woodworking craft and
          household welding subcategories.
        </p>
      </Container>
    </section>
  );
};

export default Instruction;
