export const AMZ_CATEGORIES_CONTENT = {
  LISTS: [
    [
      "Industrial & Scientific",
      "Health, Household & Babycare",
      "Electronics",
      "Toys & Games",
      "Grocery & Gourmet Food",
      "Baby",
    ],
    [
      "Beauty & Personal Care",
      "Clothing, Shoes & Jewelry",
      "Pet Supplies",
      "Tools & Home Improvement",
      "Home & Kitchen",
      "Cell Phones & Accessories",
      "Office Products",
    ],
    [
      "Musical Instruments",
      "Arts, Crafts & Sewing",
      "Sports & Outdoors",
      "Garden & Outdoor",
      "Kitchen & Dining",
      "Automotive Parts & Accessories",
    ],
  ],
};
