import PNGGraphic from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic.png";
import PNGGraphic2x from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic@2x.png";
import WEBPGraphic from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic.webp";
import WEBPGraphic2x from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic@2x.webp";
import PNGGraphicMob from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic@mob.png";
import PNGGraphicMob2x from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic@mob2x.png";
import WEBPGraphicMob from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic@mob.webp";
import WEBPGraphicMob2x from "../../../assets/images/raster/pages/personal-product-ideas/BigData/graphic@mob2x.webp";

export const BIG_DATA_CONTENT = {
  IMAGES: {
    desktop: {
      png: PNGGraphic,
      png2x: PNGGraphic2x,
      webp: WEBPGraphic,
      webp2x: WEBPGraphic2x,
    },
    mobile: {
      png: PNGGraphicMob,
      png2x: PNGGraphicMob2x,
      webp: WEBPGraphicMob,
      webp2x: WEBPGraphicMob2x,
    },
  }
}
