import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { getTrueClasses } from "../../../../../helpers";

const PointList = ({ arrayOfItems, customClassName, customItemClassName }) => {
  return (
    <ul className={getTrueClasses("UI-PointList", customClassName)}>
      {arrayOfItems.map((item, index) => {
        return (
          <li
            key={index}
            className={getTrueClasses(
              "UI-PointList__item",
              customItemClassName
            )}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
};

PointList.propTypes = {
  arrayOfItems: PropTypes.array.isRequired,
  customClassName: PropTypes.string,
  customItemClassName: PropTypes.string,
};

export default PointList;
