import PNGIntroImage from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro.png";
import PNGIntroImageTab from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@tab.png";
import PNGIntroImageMob from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@mob.png";
import PNGIntroImage2x from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@2x.png";
import PNGIntroImageTab2x from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@tab2x.png";
import PNGIntroImageMob2x from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@mob2x.png";

import WEBPIntroImage from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro.webp";
import WEBPIntroImageTab from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@tab.webp";
import WEBPIntroImageMob from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@mob.webp";
import WEBPIntroImage2x from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@2x.webp";
import WEBPIntroImageTab2x from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@tab2x.webp";
import WEBPIntroImageMob2x from "../../../assets/images/raster/pages/personal-product-ideas/HomeIntro/intro@mob2x.webp";


export const INTRO_CONTENT = {
  LIST_ITEMS: [
    "Get unique, ready-to-launch profitable products with growing sales weekly",
    "Save time needed to look for new items that will expand the product line of your existing online business",
    "Focus your time and attention on growing niche trends that will help you launch or scale your business within new Amazon categories",
  ],
  IMAGES: {
    desktop: {
      png: PNGIntroImage,
      png2x: PNGIntroImage2x,
      webp: WEBPIntroImage,
      webp2x: WEBPIntroImage2x,
    },
    tablet: {
      png: PNGIntroImageTab,
      png2x: PNGIntroImageTab2x,
      webp: WEBPIntroImageTab,
      webp2x: WEBPIntroImageTab2x,
    },
    mobile: {
      png: PNGIntroImageMob,
      png2x: PNGIntroImageMob2x,
      webp: WEBPIntroImageMob,
      webp2x: WEBPIntroImageMob2x,
    },
  }
}
