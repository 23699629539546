export const INSTRUCTION_CONTENT = {
  LIST: [
    {
      number: "01.",
      description:
        "Go to your AMZScout account and choose “Personal Ideas” on the left menu",
      path: "/app/#/reports/customideas",
    },
    {
      number: "02.",
      description:
        "Use the product search by keyword to choose an Amazon subcategory",
      path: null,
    },
    {
      number: "03.",
      description:
        "Subscribe to receive up to five hot product ideas per month in your selected subcategory to see growing monthly revenue for your business",
      path: null,
    },
  ],
};
