import React from "react";
import Container from "../../common/Container";
import { BIG_DATA_CONTENT } from "./index.content";
import SVGSubmitIcon from "../../../assets/images/vector/pages/personal-product-ideas/BigData/submit.svg";
import "./index.scss";
const { desktop, mobile } = BIG_DATA_CONTENT.IMAGES;

const BigData = () => {
  return (
    <section className="PgPPI-BigData">
      <Container mediumSize modeClass="PgPPI-BigData-container">
        <p className="PgPPI-BigData__title">
          With Big Data at our disposal, we are able to compare 50,000+ product
          categories on Amazon to provide you with:
        </p>
        <div className="PgPPI-BigData-box">
          <div className="PgPPI-BigData__image">
            <picture>
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${mobile.webp} 1x, ${mobile.webp2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${mobile.png} 1x, ${mobile.png2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px)"
                srcSet={`${desktop.webp} 1x, ${desktop.webp2x} 2x`}
              />
              <source
                media="(min-width: 768px)"
                srcSet={`${desktop.png} 1x, ${desktop.png2x} 2x`}
              />
              <img src={desktop.png} alt="" loading="lazy" />
            </picture>
          </div>
          <ul className="PgPPI-BigData-list">
            {[
              "The most profitable and least competitive products",
              "Product ideas without restrictions of seasonality, and with stable demand",
            ].map((item, index) => {
              return (
                <li key={index} className="PgPPI-BigData-list__item">
                  <img src={SVGSubmitIcon} alt="icon" />
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default BigData;
