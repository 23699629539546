import PNGReportOne from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one.png";
import PNGReportOne2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@2x.png";
import PNGReportOneTab from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@tab.png";
import PNGReportOneTab2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@tab2x.png";
import PNGReportOneMob from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@mob.png";
import PNGReportOneMob2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@mob2x.png";

import WEBPReportOne from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one.webp";
import WEBPReportOne2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@2x.webp";
import WEBPReportOneTab from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@tab.webp";
import WEBPReportOneTab2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@tab2x.webp";
import WEBPReportOneMob from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@mob.webp";
import WEBPReportOneMob2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-one@mob2x.webp";

import PNGReportTwo from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two.png";
import PNGReportTwo2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@2x.png";
import PNGReportTwoTab from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@tab.png";
import PNGReportTwoTab2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@tab2x.png";
import PNGReportTwoMob from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@mob.png";
import PNGReportTwoMob2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@mob2x.png";

import WEBPReportTwo from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two.webp";
import WEBPReportTwo2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@2x.webp";
import WEBPReportTwoTab from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@tab.webp";
import WEBPReportTwoTab2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@tab2x.webp";
import WEBPReportTwoMob from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@mob.webp";
import WEBPReportTwoMob2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-two@mob2x.webp";

import PNGReportThree from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three.png";
import PNGReportThree2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@2x.png";
import PNGReportThreeTab from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@tab.png";
import PNGReportThreeTab2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@tab2x.png";
import PNGReportThreeMob from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@mob.png";
import PNGReportThreeMob2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@mob2x.png";

import WEBPReportThree from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three.webp";
import WEBPReportThree2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@2x.webp";
import WEBPReportThreeTab from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@tab.webp";
import WEBPReportThreeTab2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@tab2x.webp";
import WEBPReportThreeMob from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@mob.webp";
import WEBPReportThreeMob2x from "../../../assets/images/raster/pages/personal-product-ideas/ReportCta/report-three@mob2x.webp";

export const REPORT_CTA_CONTENT = {
  IMAGES: [
    {
      desktop: {
        png: PNGReportOne,
        png2x: PNGReportOne2x,
        webp: WEBPReportOne,
        webp2x: WEBPReportOne2x
      },
      tab: {
        png: PNGReportOneTab,
        png2x: PNGReportOneTab2x,
        webp: WEBPReportOneTab,
        webp2x: WEBPReportOneTab2x
      },
      mobile: {
        png: PNGReportOneMob,
        png2x: PNGReportOneMob2x,
        webp: WEBPReportOneMob,
        webp2x: WEBPReportOneMob2x
      }
    },
    {
      desktop: {
        png: PNGReportTwo,
        png2x: PNGReportTwo2x,
        webp: WEBPReportTwo,
        webp2x: WEBPReportTwo2x
      },
      tab: {
        png: PNGReportTwoTab,
        png2x: PNGReportTwoTab2x,
        webp: WEBPReportTwoTab,
        webp2x: WEBPReportTwoTab2x
      },
      mobile: {
        png: PNGReportTwoMob,
        png2x: PNGReportTwoMob2x,
        webp: WEBPReportTwoMob,
        webp2x: WEBPReportTwoMob2x
      }
    },
    {
      desktop: {
        png: PNGReportThree,
        png2x: PNGReportThree2x,
        webp: WEBPReportThree,
        webp2x: WEBPReportThree2x
      },
      tab: {
        png: PNGReportThreeTab,
        png2x: PNGReportThreeTab2x,
        webp: WEBPReportThreeTab,
        webp2x: WEBPReportThreeTab2x
      },
      mobile: {
        png: PNGReportThreeMob,
        png2x: PNGReportThreeMob2x,
        webp: WEBPReportThreeMob,
        webp2x: WEBPReportThreeMob2x
      }
    },
  ]
};
